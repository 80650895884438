<template>
  <v-container fluid>
    <v-flex>
      <v-card>
        <v-toolbar color="#0f2e8c" flat>
          <h3 class="white--text">KHO HÀNG - HÓA ĐƠN</h3>
          <v-spacer/>
          <v-btn
              class="mx-2"
              color="blue dark-3"
              dark
              @click="doConfirmUnExportItems"
              v-if="loginInfo._perm === 'admin'"
              :disabled="selectedItems.length <= 0 || SelectedUnExportItems.length !== selectedItems.length || UnExportState"
              :loading="UnExportState"
          >
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon> Hủy xuất kho
          </v-btn>
          <v-btn
              class="mx-2"
              color="blue dark-3"
              dark
              @click="doConfirmExportItems"
              v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
              :disabled="selectedItems.length <= 0 || SelectedExportItems.length !== selectedItems.length || ConfirmExportState"
              :loading="ConfirmExportState"
          >
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon> Xác nhận xuất
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row>
            <v-col
                cols="4"
            >
              <v-card
                  class="mx-2"
              >
                <v-card-title>Thống kê</v-card-title>
                <v-card-text>
                  <div class="ma-1">
                    <strong>Tổng</strong>: {{DoiTien(Stats.Tong)}}
                  </div>
                  <div class="ma-1">
                    <strong>Đã thanh toán</strong>: {{DoiTien(Stats.TongTT)}}
                  </div>
                  <div class="ma-1">
                    <strong>Công nợ</strong>:{{DoiTien(Stats.TongCN)}}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col
                    cols="auto"
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' "
                >
                  <v-select
                      v-model="Filter.Sale"
                      :items="Sales"
                      item-value="_id"
                      item-text="Username"
                      label="Sale"
                  />
                </v-col>
                <v-col
                  cols="auto"
                >
                  <v-select
                    v-model="Filter.ThanhToan"
                    :items="[{text:'Tất cả',value:null}, {text:'COD',value:'COD'}, {text:'Chuyển khoản',value:'CK'}]"
                    label="H.Thức thanh toán"
                  />
                </v-col>
                <v-col
                    cols="auto"
                >
                  <v-select
                      v-model="Filter.TrangThai"
                      :items="[{text:'Tất cả',value:null}, {text:'Bill tạm',value:'bill_tam'}, {text:'Chờ thanh toán',value:'cho_tt'}, {text:'Đã thanh toán',value:'khoa_tt'}]"
                      label="T.Thái thanh toán"
                  />
                </v-col>
                <v-col
                    cols="auto"
                >
                  <v-menu
                      ref="menu_created_at"
                      v-model="menu_created_at"
                      :close-on-content-click="false"
                      :return-value.sync="Filter.created_at"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="CreatedAt"
                          label="Lọc thời gian"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="FilterTime"
                        no-title
                        scrollable
                        range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="doCancelFilterByCreated"
                      >
                        Hủy
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="doSaveFilterByCreated"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="auto"
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                >
                  <v-checkbox
                      v-model="Filter.Cancelled"
                      label="Đơn hàng đã hủy"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field
                      v-model="Filter.Search"
                      label="Tìm kiếm theo Mã hóa đơn"
                      class="mx-2"
                      clearable
                      persistent-placeholder
                      @click:clear="doClearSearch"
                  />
                </v-col>
                <v-col
                  cols="auto"
                >
                  <v-btn
                    :dark="!loading"
                    :disabled="loading"
                    :loading="loading"
                    color="blue"
                    @click="doDownload"
                  >
                    Tìm kiếm
                    <v-icon right>
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
              v-model="selectedItems"
              fixed-header
              show-select
              selectable-key="_id"
              item-key="_id"
              :loading="loading"
              :headers="headers"
              :items="items"
              loading-text="Đang tải dữ liệu..."
              :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50,100]}"
              :items-per-page="100"
          >
            <template v-slot:[`item.Exported`]="{item}">
              <v-btn
                dark
                :color="item.Exported ? 'blue':'red'"
                small
              >
                <v-icon>
                  mdi-{{item.Exported ? 'checkbox-outline':'checkbox-blank-outline'}}
                </v-icon>
                {{item.Exported ? 'Xác nhận xuất':'Chưa xác nhận'}}
              </v-btn>
            </template>
            <template v-slot:[`item.Countdown`]="{item}">
              <Countdown
                  :end="parseDateTime(item.Countdown)"
                  v-if="item.Countdown && (item.ThanhToan === 'CK' && item.TrangThaiThanhToan !== 'khoa_tt')"
              />
            </template>
            <template v-slot:[`item.Items`]="{item}">
              <v-btn
                dark
                color="blue"
                small
                @click="doToggleItemsBox(item)"
                block
              >
                {{item.Items.length}} sản phẩm
              </v-btn>
            </template>
            <template v-slot:[`item.Notes`]="{item}">
              <v-btn
                  dark
                  :color="!item.Notes || (item.Notes && item.Notes.length === 0) ? 'blue':item.Notes[0].Staff === loginInfo._id ? 'blue':'green'"
                  small
                  block
                  @click="doViewNote(item)"
              >
                {{!item.Notes || (item.Notes && item.Notes.length === 0) ? 'Chưa có ghi chú':item.Notes[0].Staff.Username}}
              </v-btn>
            </template>
            <template v-slot:[`item.Image`]="{item}">
              <v-img
                v-if="item.Image"
                :src="`${statics}/${item.Image}`"
                @click="doViewImage(item)"
                max-width="125px"
                max-height="125px"
                class="mx-auto"
              />
              <template
                  v-if="item.ThanhToan === 'CK' && item.TrangThaiThanhToan !== 'khoa_tt'"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :dark="uploadInvoiceImage !== item._id"
                        color="blue"
                        class="mx-2 mb-1"
                        :disabled="uploadInvoiceImage === item._id"
                        :loading="uploadInvoiceImage === item._id"
                        @click="doStartUploadHD(item)"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                    >
                      Up hình
                      <v-icon right>mdi-progress-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Tải lên hình ảnh chuyển khoản</span>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:[`item.TrangThaiThanhToan`]="{item}">
              <v-btn
                dark
                :color="TrangThai(item.TrangThaiThanhToan, item.ThanhToan)['color']"
              >
                {{TrangThai(item.TrangThaiThanhToan, item.ThanhToan)['text']}}
              </v-btn>
            </template>
            <template v-slot:[`item.ThanhToanLoi`]="{item}">
              <template
                  v-if="item.TrangThaiThanhToan === 'khoa_tt' && !item.Exported && (loginInfo._perm === 'admin' || loginInfo._perm === 'manager')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :dark="paymentState !== item._id"
                        color="red"
                        class="mx-2"
                        :disabled="paymentState === item._id"
                        :loading="paymentState === item._id"
                        @click="doPaymentError(item)"
                        v-bind="attrs"
                        v-on="on"
                        small
                        outlined
                    >
                      Thanh toán lỗi
                      <v-icon right>mdi-alert-circle-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Thanh toán lỗi</span>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:[`item.HuyTT`]="{item}">
              <template
                  v-if="(item.TrangThaiThanhToan === 'khoa_tt' && loginInfo._perm === 'admin')"
              >
                <v-tooltip
                    bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :dark="cancelPaidState === null"
                        icon
                        color="blue"
                        class="mx-2"
                        :disabled="cancelPaidState === item._id"
                        :loading="cancelPaidState === item._id"
                        @click="doCancelPaid(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-check-decagram</v-icon>
                    </v-btn>
                  </template>
                  <span>Hủy thanh toán</span>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:[`item.XacNhanTT`]="{item}">
              <template
                  v-if="((item.ThanhToan === 'COD' && item.TrangThaiThanhToan === 'cho_tt') || (item.ThanhToan === 'CK' && item.TrangThaiThanhToan ==='da_tt') || item.TrangThaiThanhToan === 'loi_tt') && (loginInfo._perm === 'admin' || loginInfo._perm === 'manager')"
              >
                <v-tooltip
                    bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :dark="confirmPaidState !== item._id"
                        icon
                        :color="item.TrangThaiThanhToan === 'loi_tt' ? 'orange':'green'"
                        class="mx-2"
                        :disabled="confirmPaidState === item._id"
                        :loading="confirmPaidState === item._id"
                        @click="doConfirmPaid(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-check-decagram</v-icon>
                    </v-btn>
                  </template>
                  <span>Xác nhận đã thanh toán</span>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:[`item.HuyDon`]="{item}">
              <template
                  v-if="(((item.ThanhToan === 'COD' && item.TrangThaiThanhToan === 'bill_tam') || item.TrangThaiThanhToan === 'loi_tt') && (loginInfo._perm === 'admin' || loginInfo._perm === 'manager') || loginInfo._perm === 'admin')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :dark="cancelState !== item._id"
                        icon
                        color="red"
                        class="mx-2"
                        :disabled="cancelState === item._id"
                        :loading="cancelState === item._id"
                        @click="doCancel(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Hủy hóa đơn</span>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:[`item.DownloadHD`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      large
                      :dark="downloadInvoiceExcel !== item._id"
                      icon
                      color="green"
                      :disabled="downloadInvoiceExcel === item._id"
                      :loading="downloadInvoiceExcel === item._id"
                      @click="doExportInvoice(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-download-box</v-icon>
                  </v-btn>
                </template>
                <span>Tải về file Excel hóa đơn</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.MaHD`]="{item}">
              <v-btn
                  outlined
                  dark
                  :color="item.Cancelled === true ? 'red':'blue'"
                  @click="doToggleInvoicePage(item)"
                  class="ma-auto"
              >
                {{item.MaHD}}
              </v-btn>
            </template>
            <template v-slot:[`item.ThanhToan`]="{item}">
              <v-btn
                dark
                color="orange"
                v-if="item.ThanhToan === 'COD'"
                small
              >
                COD
              </v-btn>
              <v-btn
                  dark
                  color="green"
                  v-if="item.ThanhToan === 'CK'"
                  small
              >
                Chuyển khoản
              </v-btn>
            </template>
            <template v-slot:[`item.created_at`]="{item}">
              {{parseDateTime(item.created_at)}}
            </template>
            <template v-slot:[`item.Tong`]="{item}">
              {{DoiTien(item.Tong)}}
            </template>
            <template v-slot:[`item.DaThanhToan`]="{item}">
              {{DoiTien(item.DaThanhToan)}}
            </template>
            <template v-slot:[`item.CongNo`]="{item}">
              {{DoiTien(item.CongNo)}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-file-input
        ref="uploaderHD"
        v-model="HDImage"
        accept="image/*"
        class="hide"
        @change="doUploadHD"
    />
    <LightBox
        ref="lightbox2"
        :media="Media"
        :show-light-box="false"
    />
    <v-dialog
      v-model="boxNotes"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          GHI CHÚ HÓA ĐƠN - {{NoteData.MaHD}}
          <v-spacer />
          <v-btn
              dark
              @click="boxNotes = false"
          >
            Đóng
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="Note in Notes"
              :key="Note._id"
            >
              <v-list-item-title>
                {{Note.Content}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{Note.Staff.Username}} ({{parseDateTime(Note.Date)}})
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <span
              v-if="Notes.length === 0 && !NoteState"
              class="text-h6 red--text"
          >
            Chưa có ghi chú
          </span>
          <Loader :status="NoteState" />
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="10"
            >
              <v-textarea
                v-model="NoteData.Content"
                label="Nội dung"
                class="mx-2"
              />
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                  :dark="!NoteState && NoteData.Content"
                  color="blue"
                  icon
                  large
                  :disabled="NoteState || !NoteData.Content"
                  :loading="NoteState"
                  @click="doAddNote"
              >
                <v-icon>
                  mdi-send
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="boxItems"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          Danh sách sản phẩm thuộc đơn hàng {{ItemsData.MaHD}}
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Mã Kho
                </th>
                <th class="text-left">
                  Hình ảnh
                </th>
                <th class="text-left">
                  Trạng thái kho
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in ItemsData.Items"
                  :key="item.MaKho"
              >
                <td>{{ item.MaKho }}</td>
                <td>
                  <v-img
                      v-if="item.Images && item.Images.length > 0"
                      :src="item.Images[0]"
                      max-width="100px"
                      max-height="100px"
                  />
                  <span
                    v-else
                    class="mx-auto red--text"
                  >
                    Không có hình ảnh
                  </span>
                </td>
                <td>
                  <template v-if="item.Ton === -2">
                    <v-btn
                      dark
                      small
                      color="blue"
                    >
                      Xin xuất
                    </v-btn>
                  </template>
                  <template v-if="item.Ton === 0">
                    <v-btn
                        dark
                        small
                        color="red"
                    >
                      Đã xuất
                    </v-btn>
                  </template>
                  <template v-if="item.Ton === -3">
                    <v-btn
                        dark
                        small
                        color="orange"
                    >
                      Xác nhận xuất
                    </v-btn>
                  </template>
                  <template v-if="item.Ton === -4">
                    <v-btn
                        dark
                        small
                        color="green"
                    >
                      Hoàn thành
                    </v-btn>
                  </template>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            @click="boxItems = false"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <HoaDon
        ref="HoaDon"
    />
  </v-container>
</template>

<script>
import Query from '@/plugins/query/kho-hoa-don';
import HeaderDefault from '@/plugins/headers/default.json';
import Header from '@/plugins/headers/kho-hoa-don.json';
import HeaderAdmin from '@/plugins/headers/kho-hoa-don-admin.json';
import {mapGetters} from "vuex";
import {parseDateTime} from "@/plugins/helper/timezone";
import {DoiTien} from "@/plugins/helper/string";
import {Status} from "@/plugins/helper/dstatus";
import LightBox from 'vue-it-bigger'
import Loader from '@/components/Loader.vue';
import Global from "@/global";
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
import Countdown from '@/components/Countdown.vue';
import HoaDon from '@/components/kho/HoaDon.vue';
export default {
  name: "Kho-Hoa-Don",
  components:{
    Loader,
    LightBox,
    Countdown,
    HoaDon,
  },
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
    SelectedUnExportItems(){
      return this.selectedItems.filter(item=>item.Exported);
    },
    SelectedExportItems(){
      return this.selectedItems.filter(item=>(((item.TrangThaiThanhToan === 'khoa_tt' && item.ThanhToan === 'CK') || (item.ThanhToan === 'COD' && item.TrangThaiThanhToan === 'bill_tam')) && !item.Exported));
    },
    CreatedAt() {
      const data = this.FilterTime;
      if(data.length > 0) {
        return this.FilterTime.join(' ~ ')
      }
      return 'Lựa chọn thời gian'
    },
    statics(){
      return `${Global.staticTOP}/kho-hoa-don`;
    },
    Sales(){
      return [{_id:null,Username:"Tất cả"}].concat(this.Sale);
    },
    headers(){
      const header = this.loginInfo._perm === 'admin' ? HeaderAdmin:Header;
      return header.map(item=>({
        ...HeaderDefault,
        ...item,
      })).filter(item=>(item.value ==='Sale.Username' && (this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager')) || item.value !== 'Sale.Username');
    }
  },
  data(){
    return {
      UnExportState: false,
      selectedItems:[],
      ItemsData:{
        MaHD: null,
        Items:[],
      },
      boxItems: false,
      menu_created_at: false,
      NoteState: false,
      boxNotes: false,
      NoteData:{
        MaHD: null,
        ID: null,
        Content: null,
      },
      Notes: [],
      Media:[],
      paymentState: null,
      HDID: null,
      HDImage: null,
      cancelState: null,
      cancelPaidState: null,
      confirmPaidState: null,
      uploadInvoiceImage: null,
      downloadInvoiceExcel: null,
      loading: false,
      Search: null,
      totalItems: 0,
      FilterTime: [],
      Stats:{
        Tong:0,
        TongTT: 0,
        TongCN: 0,
      },
      items: [],
      Sale: [],
      Filter: {
        Search: null,
        ThanhToan: null,
        TrangThai: null,
        Sale: null,
        Cancelled: false,
        created_at: null,
      },
      ConfirmExportState: false,
      notice: new Status(this.$swal),
    }
  },
  methods:{
    parseDateTime,
    DoiTien,
    doToggleInvoicePage(item){
      if(item.Cancelled) return;
      return this.$refs.HoaDon.doOpen(item);
    },
    doConfirmUnExportItems(){
      if(this.SelectedUnExportItems.length > 0){
        this.UnExportState = true;
        const app = this;
        const items = this.SelectedUnExportItems.map(item => item.id);
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            return Query.qrConfirmCancelExport(items).finally(() => app.doDownload());
          }
        }).finally(()=>{
          this.UnExportState = false;
          this.selectedItems = [];
        });
      }
    },
    doConfirmExportItems(){
      if(this.SelectedExportItems.length > 0){
        this.ConfirmExportState = true;
        const app = this;
        const items = this.SelectedExportItems.map(item => item.id);
        return this.notice.confirm("Xác nhận ?", "Hành động của bạn sẽ không thể phục hồi !!!").then(result=> {
          if(result) {
            return Query.qrConfirmExport(items).finally(() => app.doDownload());
          }
        }).finally(()=>{
          this.ConfirmExportState = false;
          this.selectedItems = [];
        });
      }
    },
    doToggleItemsBox(item){
      this.ItemsData = {
        Items: item.Items,
        MaHD: item.MaHD,
      };
      this.boxItems = true;
    },
    doSaveFilterByCreated(){
      this.Filter.created_at = this.FilterTime;
      return this.$refs.menu_created_at.save(this.FilterTime)
    },
    doCancelFilterByCreated(){
      this.FilterTime = [];
      this.$refs.menu_created_at.save(this.FilterTime);
      return this.menu_created_at = false;
    },
    doAddNote(){
      this.NoteState = true;
      return Query.qrAddNote(this.NoteData.ID, this.NoteData.Content).then(json=>{
        if(json.success){
          this.doDownload();
          return this.doLoadNote();
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).finally(()=>{
        this.NoteState = false;
      })
    },
    doViewNote(item){
      this.NoteData = {
        Content: null,
        ID: item._id,
        MaHD: item.MaHD,
      };
      this.boxNotes = true;
      return this.doLoadNote();
    },
    doLoadNote(){
      this.NoteState = true;
      return Query.qrNotes(this.NoteData.ID).then(json=>{
        if(json.data) this.Notes = json.data;
        if(json.error){
          this.notice.error(json.error);
          this.boxNotes = false;
        }
      }).finally(()=>{
        this.NoteState = false;
      })
    },
    doViewImage(item){
      this.Media = [{
        type: 'image',
        src: `${this.statics}/${item.Image}`,
        thumb: `${this.statics}/${item.Image}`,
      }];
      this.$refs.lightbox2.showImage(0);
    },
    TrangThai(Status, Type){
      let _default = {
        text:'Dữ liệu lỗi',
        color:'red'
      }
      if(Status === 'cho_tt'){
        Status = Type === 'COD' ? 'cho_tt_COD':'cho_tt_CK';
      }
      const data = {
        'bill_tam':{
          text: 'Bill tạm',
          color: 'blue'
        },
        'cho_tt_COD':{
          text: 'COD - Chờ thanh toán',
          color: 'orange'
        },
        'cho_tt_CK':{
          text: 'Chưa thanh toán',
          color: 'red lighten-2'
        },
        'khoa_tt':{
          text: 'Đã thanh toán',
          color: 'green'
        },
        'da_tt':{
          text: 'Đã thanh toán - Chờ xác nhận',
          color: 'yellow darken-4'
        },
        'loi_tt':{
          text: 'Lỗi thanh toán',
          color: 'orange darken-4'
        }
      }
      if(data[Status]) return data[Status];
      return _default;
    },
    doCancel(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(result=>{
        if(result){
          app.cancelState = item._id;
          return Query.qrCancel(item._id).then(json=>{
            if(json.success){
              app.notice.success(`Đã hủy hóa đơn ${item.MaHD}`);
              return app.doDownload();
            }
          }).finally(()=>{
            app.cancelState = null;
          });
        }
      });
    },
    doPaymentError(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(result=>{
        if(result){
          app.paymentState = item._id;
          return Query.qrPaymentError(item._id).then(json=>{
            if(json.success){
              app.notice.success(`Đã cập nhật thanh toán lỗi cho hóa đơn ${item.MaHD}`);
              return app.doDownload();
            }
          }).finally(()=>{
            app.paymentState = null;
          });
        }
      });
    },
    doCancelPaid(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(result=>{
        if(result){
          app.cancelPaidState = item._id;
          return Query.qrCancelPaid(item._id).then(json=>{
            if(json.success){
              app.notice.success(`Đã hủy xác nhận thanh toán cho hóa đơn ${item.MaHD}`);
              return app.doDownload();
            }
          }).finally(()=>{
            app.cancelPaidState = null;
          });
        }
      });
    },
    doConfirmPaid(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(result=>{
        if(result){
          app.confirmPaidState = item._id;
          return Query.qrConfirmPaid(item._id).then(json=>{
            if(json.success){
              app.notice.success(`Đã xác nhận thanh toán cho hóa đơn ${item.MaHD}`);
              return app.doDownload();
            }
          }).finally(()=>{
            app.confirmPaidState = null;
          });
        }
      });
    },
    doUploadFileHD(uri, file, id){
      this.uploadInvoiceImage = id;
      return Query.qrUploadFile(uri, file).then(json=>{
        if(json.success){
          this.notice.success("Đã tải lên hình ảnh cho hóa đơn !!!");
          return this.doDownload();
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      }).finally(()=>{
        this.uploadInvoiceImage = null;
      });
    },
    doUploadHD() {
      if (this.HDImage) {
        this.doUploadFileHD(`/image/${this.HDID}`, this.HDImage, this.HDID);
      } else {
        this.notice.error('Vui lòng chọn hình ảnh để tải lên !!!');
      }
    },
    doStartUploadHD(item){
      this.HDID = item._id;
      this.HDImage = null;
      this.$refs.uploaderHD.$refs.input.click();
    },
    doExportInvoice(item){
      this.downloadInvoiceExcel = item._id;
      return Query.qrExport({app:this,id:item._id, MaHD:item.MaHD}).finally(()=>{
        this.downloadInvoiceExcel = null;
      });
    },
    doClearSearch(){
      this.Filter.Search = null;
    },
    doSearch(){
      this.Filter.Search = this.Search;
    },
    doDownload(){
      this.loading = true;
      return Query.qrList(this.Filter).then(json=>{
        if(json.data){
          const {data, total} = json;
          if(data.items) this.items = data.items;
          if(total) this.totalItems = total;
          if(data.Sale) this.Sale = data.Sale;
          if(data.Stats) this.Stats = data.Stats;
        }
      }).finally(()=>{
        this.loading = false;
      });
    }
  },
  mounted() {
    this.doDownload();
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>