<template>
<div>
  <v-dialog
    v-model="box"
    persistent
    max-width="800px"
  >
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          <v-btn
              icon
              dark
              @click="doClose()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>HÓA ĐƠN {{Item.MaHD}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title>
            <v-btn
                dark
                color="green"
                @click="print"
            >
              <v-icon>mdi-printer-check</v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title />
        <v-card-text>
          <v-responsive
              aspect-ratio="16/9"
              max-height="600px"
              class="overflow-y-auto"
          >
            <div ref="printMe" class="ma-auto pa-1">
              <v-row>
                <v-col
                    cols="8"
                >
                  <strong>Cửa hàng điện máy Nhật Bản</strong>
                  <br />
                  <span class="text-h6 blue--text text--darken-3">
                    TO JAPAN
                  </span>
                  <br />
                  <strong>Chi nhánh HCM</strong>: 10 Phổ Quang, P2, Tân BÌnh, HCM
                  <br />
                  <strong>Chi nhánh HN</strong>: D13-TT19 Foresa 8, KDT Sinh thái Xuân Phương, Nam Từ Liêm, Hà Nội
                  <br />
                  <strong>Hotline CSKH</strong> : +84 - 0973000794
                  <br />
                  <strong>Zalo</strong>: +84 914858832
                </v-col>
                <v-col
                    cols="4"
                >
                  <v-img
                      src="/TOJAPAN-INVOICE.png"
                  />
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h4 blue--text text--darken-4">
                    Hóa đơn
                  </div>
                  <div class="pink--text mt-1">
                    <strong>{{Today}}</strong>
                  </div>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <p>
                        <strong>Người mua hàng</strong>
                      </p>
                      <p>{{Item.TenNguoiMua}}</p>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <p>
                        <strong>SĐT người mua</strong>
                      </p>
                      <p>{{Item.SDTNguoiMua}}</p>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <p>
                        <strong>Người nhận hàng</strong>
                      </p>
                      <p>{{Item.TenNguoiNhan}}</p>
                      <p>{{Item.SDTNguoiNhan}}</p>
                      <p>{{Item.DCNguoiNhan}}</p>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <p>
                        <strong>Sale</strong>
                      </p>
                      <p>{{Item.Sale.Username}}</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                    cols="4"
                >
                  <Loader :status="qrCode === null" class="mx-auto" />
                  <v-img
                    :src="`data:image/png;base64, ${qrCode}`"
                    class="mx-auto"
                    v-if="qrCode"
                    max-width="200px"
                    max-height="200px"
                  />
                  <p>
                    <strong>Mã hóa đơn</strong>
                  </p>
                  <p>{{Item.MaHD}}</p>
                  <p>
                    <strong>Hình thức thanh toán</strong>
                  </p>
                  <p>{{Item.ThanhToan === 'COD' ? 'COD':'Chuyển khoản'}}</p>
                </v-col>
              </v-row>
              <v-divider />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left blue--text text--darken-4 mx-auto" style="width: 60%">
                      Mô tả
                    </th>
                    <th class="text-left blue--text text--darken-4 mx-auto" style="width: 10%">
                      Qty
                    </th>
                    <th class="text-left blue--text text--darken-4 mx-auto" style="width: 15%">
                      Đơn giá
                    </th>
                    <th class="text-left blue--text text--darken-4 mx-auto" style="width: 15%">
                      Thành tiền
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in Item.Items"
                      :key="item.MaKho"
                  >
                    <td class="mx-auto">
                      {{item.MaKho}}
                    </td>
                    <td class="mx-auto">
                      1
                    </td>
                    <td class="mx-auto">
                      {{DoiTien(item.GiaNY)}}
                    </td>
                    <td class="mx-auto">
                      {{DoiTien(item.GiaNY)}}
                    </td>
                  </tr>
                  <tr
                      v-for="(item,index) in Item.PhuThu"
                      :key="index"
                      v-if="Item.PhuThu && Item.PhuThu.length > 0"
                  >
                    <td class="mx-auto">
                      {{item.Muc}}
                    </td>
                    <td class="mx-auto">
                      1
                    </td>
                    <td class="mx-auto">
                      {{DoiTien(item.Phi)}}
                    </td>
                    <td class="mx-auto">
                      {{DoiTien(item.Phi)}}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider />
              <v-row>
                <v-col
                    cols="7"
                >
                  <br />
                  <Loader :status="barCode === null" class="mx-auto" />
                  <v-img
                      :src="`data:image/jpeg;base64, ${barCode}`"
                      class="mx-auto"
                      v-if="barCode"
                      max-height="200px"
                      max-width="350px"
                  />
                </v-col>
                <v-col
                    cols="5"
                >
                  <v-row>
                    <v-col
                        cols="4"
                        class="blue--text"
                    >
                      Tạm tính
                    </v-col>
                    <v-col
                        cols="8"
                    >
                      <strong>{{DoiTien(Item.TamTinh)}}</strong>
                    </v-col>
                    <v-col
                        cols="4"
                        class="blue--text"
                    >
                      Điều chỉnh
                    </v-col>
                    <v-col
                        cols="8"
                    >
                      {{Item.DieuChinh === 0 ? DoiTien(0):`-${DoiTien(Item.DieuChinh)}`}}
                    </v-col>
                    <v-col
                        cols="4"
                    />
                    <v-col
                        cols="8"
                        class="pink--text text-h6"
                    >
                      {{DoiTien(Item.TamTinh-Item.DieuChinh)}}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-responsive>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            @click="doClose()"
          >
            Đóng
          </v-btn>
        </v-card-actions>
        <Loading :status="loading" />
      </v-card>
  </v-dialog>
</div>
</template>

<script>
import {parseDateTime} from "@/plugins/helper/timezone";
import Loader from '@/components/Loader.vue';
import {DoiTien} from "@/plugins/helper/string";
import Query from '@/plugins/query/kho-hoa-don';
import Loading from '@/components/base/Loading.vue'
export default {
  name: "HoaDon",
  components:{
    Loader,
    Loading,
  },
  computed:{
    Today(){
      return parseDateTime();
    }
  },
  data(){
    return {
      loading: false,
      box: false,
      barCode: null,
      qrCode: null,
      Item:{
        _id: null,
        MaHD: null,
        TenNguoiMua: null,
        TenNguoiNhan: null,
        SDTNguoiMua: null,
        SDTNguoiNhan: null,
        DCNguoiNhan: null,
        Sale:{
          Username: null,
        },
        Items:[],
        TamTinh: 0,
        DieuChinh: 0,
      },
    }
  },
  methods:{
    DoiTien,
    async print() {
      this.loading = true
      const el = this.$refs.printMe;
      const options = {
        allowTaint: true,
        type: 'dataURL'
      }
      let a = document.createElement("a");
      a.href = await this.$html2canvas(el, options);
      a.download = this.Item.MaHD+".png";
      a.click()
      this.loading = false
    },
    doClose(){
      this.box = false;
      this.qrCode= null;
      this.barCode = null;
      return;
    },
    doOpen(item){
      this.qrCode= null;
      this.barCode = null;
      this.Item = item;
      this.box = true;
      return this.doLoad();
    },
    doLoad(){
      this.qrCode = null;
      this.barCode = null;
      return Query.qrImages(this.Item._id).then(json=>{
        const {qrcode, barcode} = json.data;
        if(qrcode) this.qrCode = qrcode;
        if(barcode) this.barCode = barcode;
      })
    },
  }
}
</script>

<style scoped>

</style>